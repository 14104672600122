var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-container"},[_c('div',{staticClass:"profile-info"},[_c('div',{staticClass:"profile-avatar",on:{"click":_vm.openDialog}},[_c('img',{attrs:{"src":_vm.getImageUrl(_vm.user.avatar, 'UserImg'),"alt":"头像"}})]),_c('el-dialog',{attrs:{"title":"修改头像","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-upload',{attrs:{"action":"/api/update-avatar","headers":_vm.headers,"name":"avatar","list-type":"picture-card","on-success":_vm.handleSuccess,"on-error":_vm.handleError}},[_c('i',{staticClass:"el-icon-plus"})]),_c('p',[_vm._v("点击上传新的头像。")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")])],1)],1),_c('div',{staticClass:"username"},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.user.username))])])]),_c('div',{staticClass:"user-account"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.user.email))])])]),_c('button',{on:{"click":_vm.LU_logout}},[_vm._v("登出")])],1),_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("最近浏览")])]),_c('div',{staticClass:"profile-text item"},[_c('el-row',[_c('el-col',[_c('router-link',{attrs:{"to":{
            name: 'Article',
            params: { id: _vm.lastViewedArticleZi.articleId },
          }}},[_c('el-card',{attrs:{"body-style":{ padding: '0px' },"shadow":"hover"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.getImageUrl(_vm.lastViewedArticleZi.coverImage, 'uploads')}}),_c('div',{staticStyle:{"padding":"30px"}},[_c('span',[_vm._v(_vm._s(_vm.lastViewedArticleZi.title))]),_c('span',[_vm._v(_vm._s(_vm.lastViewedArticleZi.author))])])])],1)],1)],1)],1)]),_c('el-card',{staticClass:"profile-box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("我的收藏")])]),_vm._l((_vm.favorites),function(actions){return _c('div',{key:actions.articleId._id,staticClass:"profile-aeticleContent"},[_c('router-link',{attrs:{"to":{
        name: 'Article',
        params: { id: actions.articleId._id },
      }}},[_c('img',{attrs:{"src":_vm.getImageUrl(actions.articleId.coverImage, 'uploads'),"alt":"封面图片"}}),_c('div',[_c('p',[_vm._v(_vm._s(actions.articleId.title))]),_c('p',[_vm._v(_vm._s(actions.articleId.BriefIntroduction))])])])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }